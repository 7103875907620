<template>
  <div>
    <div class="col-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" :class="type === CONFERENCE_MEMBERS_TYPE.reporter ? 'active' : ''">
              <a
                class="nav-link fs-14"
                href="javascript:;"
                @click="handleChangeType(CONFERENCE_MEMBERS_TYPE.reporter)"
              >
                {{$t('conference.lbl_speaker')}} ({{
                membersData.total_speakers||0
                }})
              </a>
            </li>
            <li class="nav-item" :class="type === CONFERENCE_MEMBERS_TYPE.admin ? 'active' : ''">
              <a
                class="nav-link fs-14"
                href="javascript:;"
                @click="handleChangeType(CONFERENCE_MEMBERS_TYPE.admin)"
              >
                {{$t('conference.lbl_admin')}} ({{
                membersData.total_admin||0
                }})
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div :set="(mbs = membersData.data)" class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table no--border table-patient-list">
          <thead>
            <tr>
              <th style="width: 30%;">
                <span
                  class="txt-grey-600 fs-14 fw-600 whitespace-nowrap"
                >{{$t('circle_feature.lbl_information')}}</span>
              </th>
              <th style="width: 30%;">
                <span
                  class="txt-grey-600 fs-14 fw-600 whitespace-nowrap"
                >{{$t('circle_feature.lbl_position')}}</span>
              </th>
              <th style="width: 30%;">
                <span
                  class="txt-grey-600 fs-14 fw-600 whitespace-nowrap"
                >{{$t('circle_feature.lbl_workplace')}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="member in mbs"
              class="cursor-pointer custom"
              :key="member.id"
              :set="(user_info = member.user_info)"
            >
              <td
                v-if="user_info"
                :data-label="$t('circle_feature.lbl_information')"
                @click="goToProfile(member.user_info)"
              >
                <div class="px-3 flex items-center w-full">
                  <div class="mr-2">
                    <!-- <span
                      v-if="user_info.avatar"
                      class="avatar avatar-rounded mr-0"
                      :style="
                            'background-image: url(' +
                            getImageURL(user_info.avatar) +
                            ')'
                          "
                    ></span>-->
                    <ImageBox
                      v-if="user_info.avatar"
                      class="avatar avatar-rounded object-cover object-top border"
                      avatar
                      :source="getImageURL(user_info.avatar)"
                      :alt="getMultilangValue(user_info.multilang) ? getMultilangValue(user_info.multilang).name : user_info.name"
                    />
                    <span v-if="!user_info.avatar" class="avatar avatar-rounded mr-0">
                      {{
                      user_info.name
                      ? user_info.name.charAt(0)
                      : user_info.email
                      ? user_info.email.charAt(0)
                      : "H"
                      }}
                    </span>
                  </div>
                  <p
                    class="mb-0 fs-14 fw-600"
                  >{{ (getMultilangValue(user_info.multilang) ? getMultilangValue(user_info.multilang).name : user_info.name) || user_info.email }}</p>
                </div>
              </td>

              <td v-if="user_info" :data-label="$t('circle_feature.lbl_position')">
                <p
                  class="mb-0 fs-14 fw-500"
                >{{ getMultilangValue(user_info.multilang) ? getMultilangValue(user_info.multilang).job_title : (user_info.chuc_vu || user_info.doctor_infor && user_info.doctor_infor.chuc_vu) }}</p>
              </td>
              <td v-if="user_info" :data-label="$t('circle_feature.lbl_workplace')">
                <p
                  class="mb-0 fs-14 fw-500"
                >{{ getMultilangValue(user_info.multilang) ? getMultilangValue(user_info.multilang).workplace : (user_info && user_info.doctor_infor && user_info.doctor_infor.work) }}</p>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="4" class="text-center">
                <a
                  href="javascript:;"
                  class="txt-pri fs-16"
                  v-if="pagination.page_num < pagination.total_page"
                  @click="getConferenceCircleMembers(true)"
                >{{$t('circle_feature.btn_load_more')}}</a>
                <div class="text-center" v-if="(!mbs) && !isLoading">
                  <p class="fs-16 fw-500">{{$t('circle_feature.lbl_no_member')}}</p>
                </div>
                <div class="skeleton-line" v-if="isLoading"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import { CONFERENCE_MEMBERS_TYPE } from '../../../utils/constants'
import { LANGUAGE_CODES_FOR_DISEASE } from '@/lang'
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'CircleMembers',
  props: {},
  components: {
    ImageBox
  },
  data () {
    return {
      type: CONFERENCE_MEMBERS_TYPE.reporter,
      membersData: {},
      isLoading: false,
      CONFERENCE_MEMBERS_TYPE,
      pagination: {
        page_num: 1,
        page_size: 10,
        totalItems: 0,
        total_page: 0
      },
      appUtils
    }
  },
  computed: {
    lang () {
      return LANGUAGE_CODES_FOR_DISEASE[this.$lang.toUpperCase()]
    }
  },
  watch: {
    $route: {
      deep: true,
      handler () {
        this.getConferenceCircleMembers()
      }
    }
  },
  created () {
    this.getConferenceCircleMembers()
  },
  methods: {
    goToProfile (data) {
      const doctorId = data?.doctor_infor?.id
      if (!doctorId) return
      this.$router
        .push({ name: 'DoctorProfile', params: { id: doctorId } })
        .catch(() => {})
    },
    getImageURL (a) {
      if (a.includes('https')) return a
      return appUtils.getImageURL(a)
    },
    handleChangeType (type) {
      this.type = type
      this.getConferenceCircleMembers()
    },
    async getConferenceCircleMembers (isSeeMore) {
      if (!this.$route.params?.id && !this.$route.query?.id) return

      try {
        this.isLoading = true
        const circle_id = this.$route.params?.id || this.$route.query?.id
        const params = {
          role: this.type,
          page_num: isSeeMore
            ? this.pagination.page_num + 1
            : this.pagination.page_num,
          page_size: this.pagination.page_size
        }

        const response = await this.$rf
          .getRequest('ConferenceRequest')
          .getConferenceCircleMembers(circle_id, params)

        const responseData = response.data || {}
        if (isSeeMore) {
          this.membersData = {
            ...this.membersData,
            ...responseData,
            data: [...this.membersData.data, ...responseData.data]
          }
        } else {
          this.membersData = responseData
        }
        this.pagination = {
          page_num: responseData.page.page_num,
          page_size: responseData.page.page_size,
          total_page: responseData.page.total_page,
          totalItems: responseData.page.total
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    getMultilangValue (data) {
      if (!data) return ''
      return data.find(item => (item.Lang || item.lang) === this.lang)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
